type RegionsConfig = Record<
  Regions,
  {
    geoIds: number[];
    domain: string;
    city: string;
  }
>;

export enum Regions {
  Arkhangelsk = 29,
  Barnaul = 22,
  Chelyabinsk = 74,
  Fontanka = 78,
  Irkutsk = 38,
  Izhevsk = 18,
  Kazan = 16,
  Kemerovo = 42,
  KemerovoRugion = 142,
  KhantyMansiysk = 86,
  Kirov = 43,
  KrasnodarRugion = 23,
  Krasnoyarsk = 24,
  KrasnoyarskRugion = 124,
  Kurgan = 45,
  Lipetsk = 48,
  Magnitogorsk = 182028,
  Mosk = 1077,
  Murmansk = 51,
  NizhnyNovgorod = 52,
  NovosibirskRugion = 154,
  Nsk = 54,
  Omsk = 55,
  OmskRugion = 155,
  Orenburg = 56,
  Perm = 59,
  Pskov = 60,
  Rostov = 61,
  Ryazan = 62,
  SaintPetersburg = 47,
  Samara = 63,
  Saratov = 64,
  Sochi = 93,
  Stavropol = 26,
  Sterlitamak = 114160,
  Tambov = 68,
  Tolyatty = 142982,
  Tomsk = 70,
  TomskRugion = 170,
  Tula = 71,
  Tyumen = 72,
  Ufa = 2,
  Ulyanovsk = 73,
  VelikiiNovgorod = 53,
  Vladivostok = 25,
  Volgograd = 34,
  Vologda = 35,
  Voronezh = 36,
  Yakutsk = 14,
  YamaloNenetsAutonomousArea = 89,
  Yaroslavl = 76,
  Yekaterinburg = 66,
  YekaterinburgRugion = 166,
  Chita = 75,
  DoubleNsk = 1154,
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const REGIONS_CONFIG: RegionsConfig = {
  [Regions.Ufa]: {
    geoIds: [2],
    domain: 'ufa1.ru',
    city: 'Уфа',
  },
  [Regions.DoubleNsk]: {
    geoIds: [1154],
    domain: 'nsk54.online',
    city: 'Новосибирск',
  },
  [Regions.Ulyanovsk]: {
    geoIds: [73],
    domain: '173.ru',
    city: 'Ульяновск',
  },
  [Regions.Sterlitamak]: {
    geoIds: [2],
    domain: 'sterlitamak1.ru',
    city: 'Стерлитамак',
  },
  [Regions.Yakutsk]: {
    geoIds: [14],
    domain: '14.ru',
    city: 'Якутск',
  },
  [Regions.Kazan]: {
    geoIds: [16],
    domain: '116.ru',
    city: 'Казань',
  },
  [Regions.Izhevsk]: {
    geoIds: [18],
    domain: 'izh1.ru',
    city: 'Ижевск',
  },
  [Regions.Barnaul]: {
    geoIds: [22],
    domain: 'ngs22.ru',
    city: 'Барнаул',
  },
  [Regions.Mosk]: {
    geoIds: [77],
    domain: 'msk1.ru',
    city: 'Москва',
  },
  [Regions.KrasnodarRugion]: {
    geoIds: [23],
    domain: '93.ru',
    city: 'Краснодар',
  },
  [Regions.Sochi]: {
    geoIds: [23],
    domain: 'sochi1.ru',
    city: 'Сочи',
  },
  [Regions.KrasnoyarskRugion]: {
    geoIds: [24],
    domain: 'kbs.ru',
    city: 'Красноярск',
  },
  [Regions.Krasnoyarsk]: {
    geoIds: [24],
    domain: 'ngs24.ru',
    city: 'Красноярск',
  },
  [Regions.Stavropol]: {
    geoIds: [26],
    domain: '26.ru',
    city: 'Ставрополь',
  },
  [Regions.Arkhangelsk]: {
    geoIds: [29],
    domain: '29.ru',
    city: 'Архангельск',
  },
  [Regions.Vladivostok]: {
    geoIds: [25],
    domain: 'vladivostok1.ru',
    city: 'Владивосток',
  },
  [Regions.Volgograd]: {
    geoIds: [34],
    domain: 'v1.ru',
    city: 'Волгоград',
  },
  [Regions.Vologda]: {
    geoIds: [35],
    domain: '35.ru',
    city: 'Вологда',
  },
  [Regions.Voronezh]: {
    geoIds: [36],
    domain: 'voronezh1.ru',
    city: 'Воронеж',
  },
  [Regions.Irkutsk]: {
    geoIds: [38],
    domain: 'ircity.ru',
    city: 'Иркутск',
  },
  [Regions.Kemerovo]: {
    geoIds: [42],
    domain: 'ngs42.ru',
    city: 'Кемерово',
  },
  [Regions.KemerovoRugion]: {
    geoIds: [42],
    domain: '42.ru',
    city: 'Кемерово',
  },
  [Regions.Kirov]: {
    geoIds: [43],
    domain: '43.ru',
    city: 'Киров',
  },
  [Regions.Kurgan]: {
    geoIds: [45],
    domain: '45.ru',
    city: 'Курган',
  },
  [Regions.Lipetsk]: {
    geoIds: [48],
    domain: '48.ru',
    city: 'Липецк',
  },
  [Regions.Murmansk]: {
    geoIds: [51],
    domain: '51.ru',
    city: 'Мурманск',
  },
  [Regions.NizhnyNovgorod]: {
    geoIds: [52],
    domain: 'nn.ru',
    city: 'Нижний Новгород',
  },
  [Regions.VelikiiNovgorod]: {
    geoIds: [53],
    domain: '53.ru',
    city: 'Великий Новгород',
  },
  [Regions.Nsk]: {
    geoIds: [54],
    domain: 'ngs.ru',
    city: 'Новосибирск',
  },
  [Regions.NovosibirskRugion]: {
    geoIds: [54],
    domain: '154.ru',
    city: 'Новосибирск',
  },
  [Regions.Omsk]: {
    geoIds: [55],
    domain: 'ngs55.ru',
    city: 'Омск',
  },
  [Regions.OmskRugion]: {
    geoIds: [55],
    domain: 'omsk1.ru',
    city: 'Омск',
  },
  [Regions.Orenburg]: {
    geoIds: [56],
    domain: '56.ru',
    city: 'Оренбург',
  },
  [Regions.Perm]: {
    geoIds: [59],
    domain: '59.ru',
    city: 'Пермь',
  },
  [Regions.Pskov]: {
    geoIds: [60],
    domain: '60.ru',
    city: 'Псков',
  },
  [Regions.Rostov]: {
    geoIds: [61],
    domain: '161.ru',
    city: 'Ростов',
  },
  [Regions.Ryazan]: {
    geoIds: [62],
    domain: '62.ru',
    city: 'Рязань',
  },
  [Regions.Samara]: {
    geoIds: [63],
    domain: '63.ru',
    city: 'Самара',
  },
  [Regions.Tolyatty]: {
    geoIds: [63],
    domain: 'tolyatty.ru',
    city: 'Тольятти',
  },
  [Regions.Saratov]: {
    geoIds: [64],
    domain: '164.ru',
    city: 'Саратов',
  },
  [Regions.Yekaterinburg]: {
    geoIds: [66],
    domain: 'e1.ru',
    city: 'Екатеринбург',
  },
  [Regions.YekaterinburgRugion]: {
    geoIds: [66],
    domain: 'ekat.ru',
    city: 'Екатеринбург',
  },
  [Regions.Tambov]: {
    geoIds: [68],
    domain: '68.ru',
    city: 'Тамбов',
  },
  [Regions.Tomsk]: {
    geoIds: [70],
    domain: 'ngs70.ru',
    city: 'Томск',
  },
  [Regions.TomskRugion]: {
    geoIds: [70],
    domain: '70.ru',
    city: 'Томск',
  },
  [Regions.Tula]: {
    geoIds: [71],
    domain: '71.ru',
    city: 'Тула',
  },
  [Regions.Tyumen]: {
    geoIds: [72],
    domain: '72.ru',
    city: 'Тюмень',
  },
  [Regions.Chelyabinsk]: {
    geoIds: [74],
    domain: '74.ru',
    city: 'Челябинск',
  },
  [Regions.Magnitogorsk]: {
    geoIds: [74],
    domain: 'mgorsk.ru',
    city: 'Магнитогорск',
  },
  [Regions.Yaroslavl]: {
    geoIds: [76],
    domain: '76.ru',
    city: 'Ярославль',
  },
  [Regions.SaintPetersburg]: {
    geoIds: [47, 78],
    domain: '178.ru',
    city: 'Санкт-Петербург',
  },
  [Regions.Fontanka]: {
    geoIds: [47, 78],
    domain: 'fontanka.ru',
    city: 'Санкт-Петербург',
  },
  [Regions.KhantyMansiysk]: {
    geoIds: [86],
    domain: '86.ru',
    city: 'Ханты-Мансийск',
  },
  [Regions.YamaloNenetsAutonomousArea]: {
    geoIds: [89],
    domain: '89.ru',
    city: 'Ямало-Ненецкий АО',
  },
  [Regions.Chita]: {
    geoIds: [75],
    domain: 'chita.ru',
    city: 'Чита',
  },
};
/* eslint-enable @typescript-eslint/no-magic-numbers */

export const getGeoIdsByRegionId = (regionId: Regions): number[] =>
  REGIONS_CONFIG[regionId].geoIds;

export const getDomainByRegionId = (regionId: Regions): string =>
  REGIONS_CONFIG[regionId].domain;

export const getCityByRegionId = (regionId: Regions): string =>
  REGIONS_CONFIG[regionId].city;

export const isRegionIdMatchedWithGeoId = (regionId: Regions, geoId: number): boolean =>
  REGIONS_CONFIG[regionId].geoIds.includes(geoId);

export const isSameRegion = (oneId: Regions, twoId: Regions): boolean => oneId === twoId;
