import { guardEmptyString } from '@portal/utils/util-guards';
import { OneSignal } from '@smh/one-signal';
import type { OneSignalParams, OneSignalBrowserPermission } from '@smh/one-signal';

import { PopoverActions, PushNotificationsError } from '../application';

import { PushNotifications } from './push-notifications';
import type { PushNotificationsConfig } from './push-notifications';

type SegmentConfig = {
  key: string;
  value?: string;
};

export type PushOneSignalConfig = OneSignalParams &
  PushNotificationsConfig & { segment: SegmentConfig };

export class OneSignalNotifications extends PushNotifications {
  private readonly _defaultSegmentValue = '1';

  private readonly _oneSignalSDK: OneSignal;
  private _setupStatus: 'unsupported' | 'installed' | 'error' | 'in_progress';
  private _browserPushPermission: OneSignalBrowserPermission;
  private _isOneSignalNotificationsEnabled: boolean;
  private _userPlayerId = '';
  private _isReadyService = false;

  constructor(private readonly _config: PushOneSignalConfig) {
    super(_config);

    this._oneSignalSDK = new OneSignal();
  }

  public get isNotificationsReady(): boolean {
    return this._setupStatus === 'installed' && this._isReadyService;
  }

  public get isNotificationsNotAvailable(): boolean {
    return this._setupStatus === 'error' || this._setupStatus === 'unsupported';
  }

  public get isNotificationsEnabled(): boolean {
    return this._isOneSignalNotificationsEnabled || false;
  }

  public get isBrowserNotificationsDenied(): boolean {
    return this._browserPushPermission === 'denied';
  }

  public get userPlayerId(): string {
    return this._userPlayerId;
  }

  public async setup(): Promise<void> {
    const { appId, segment, swParams, promptSettings, welcomeNotification } =
      this._config;
    this._setupStatus = 'in_progress';

    try {
      await this._oneSignalSDK.init({
        appId,
        swParams,
        promptSettings,
        welcomeNotification,
      });
      await this._sendTag(segment);

      this._setupStatus = 'installed';
      await this._checkInitialState();
    } catch (err) {
      this._setupStatus = 'error';

      this.logger.capture({
        error: PushNotificationsError.of('Ошибка инициализации OneSignal', err as Error),
      });

      throw err;
    }
  }

  public async getUserPlayerId(): Promise<void> {
    if (guardEmptyString(this._userPlayerId)) {
      return;
    }

    try {
      this._userPlayerId = (await this._oneSignalSDK.getPlayerId()) ?? '';
    } catch (err) {
      this.logger.capture({
        error: PushNotificationsError.of(
          'Не удалось получить playerId пользователя',
          err as Error,
        ),
      });
    }
  }

  public showPopoverNotifications(force = false): void {
    this._oneSignalSDK.showSlidedownPrompt({ force });
  }

  public closePopoverNotifications(): void {
    this._oneSignalSDK.closeSlidedownPrompt();
  }

  public subscribeToNotificationsEnabledChange(cb: (value: boolean) => void): void {
    this._oneSignalSDK.subscribeToNotificationsStateChange((state) => {
      if (typeof state === 'boolean') {
        this._isOneSignalNotificationsEnabled = state;
        cb(state);
      }
    });
  }

  public unsubscribeToNotificationsEnabledChange(): void {
    this._oneSignalSDK.unsubscribeToNotificationsStateChange();
  }

  public subscribeToBrowserNotificationsDeniedChange(cb: (value: boolean) => void): void {
    this._oneSignalSDK.subscribeToBrowserPermissionChange((permission) => {
      this._browserPushPermission = permission as OneSignalBrowserPermission;
      cb(this.isBrowserNotificationsDenied);
    });
  }

  public unsubscribeToBrowserNotificationsDeniedChange(): void {
    this._oneSignalSDK.unsubscribeToBrowserPermissionChange();
  }

  public subscribePopoverShown(cb: () => void): void {
    this._oneSignalSDK.subscribePopoverShown(cb);
  }

  public unsubscribePopoverShown(): void {
    this._oneSignalSDK.unsubscribePopoverShown();
  }

  public subscribePopoverActions(cb: (event: PopoverActions) => void): void {
    this._oneSignalSDK.subscribePopoverAllow(() => {
      cb(PopoverActions.Allow);
    });
    this._oneSignalSDK.subscribePopoverDisallow(() => {
      cb(PopoverActions.Disallow);
    });
  }

  public unsubscribePopoverActions(): void {
    this._oneSignalSDK.unsubscribePopoverAllow();
    this._oneSignalSDK.unsubscribePopoverDisallow();
  }

  public subscribePopoverClosed(cb: () => void): void {
    this._oneSignalSDK.subscribePopoverClosed(cb);
  }

  public unsubscribePopoverClosed(): void {
    this._oneSignalSDK.unsubscribePopoverClosed();
  }

  public showNativePrompt(): void {
    this._oneSignalSDK.showNativePrompt();
  }

  private async _sendTag(segment: SegmentConfig) {
    const { key = '', value = this._defaultSegmentValue } = segment;

    if (!guardEmptyString(key)) {
      this.logger.capture({
        error: PushNotificationsError.of(
          'Передан пустой ключ сегмента для отправки тега',
        ),
      });
    }

    try {
      await this._oneSignalSDK.sendTag(key, value);
    } catch (err) {
      this.logger.capture({
        error: PushNotificationsError.of(
          `Не удалось отправить тэг ${key}: ${value}`,
          err as Error,
        ),
      });
    }
  }
  private async _checkInitialState() {
    const isSupport = this._oneSignalSDK.checkNotificationsSupportState();
    if (!isSupport) {
      this._setupStatus = 'unsupported';
      return;
    }

    this._browserPushPermission = await this._oneSignalSDK.checkBrowserPermission();
    this._isOneSignalNotificationsEnabled =
      await this._oneSignalSDK.checkNotificationsState();
    await this.getUserPlayerId();

    this._isReadyService = true;
  }
}
