import { AppError } from '@jtnews/shared/seedwork/frontend/application';

export class PushNotificationsError extends AppError {
  name: 'PushNotificationsError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'PushNotificationsError';
  }

  static of(message: string, error?: Error) {
    return new PushNotificationsError(message, error);
  }
}
