import { ILogger } from '@jtnews/shared/seedwork/frontend/application';

import { IPushNotifications, PopoverActions } from '../application';

export type PushNotificationsConfig = {
  deps: {
    logger: ILogger;
  };
  isUnsupportedPlatform: boolean;
};

export abstract class PushNotifications implements IPushNotifications {
  private readonly _logger: ILogger;

  constructor(config: PushNotificationsConfig) {
    const {
      deps: { logger },
    } = config;

    this._logger = logger;
  }

  protected get logger(): ILogger {
    return this._logger;
  }

  abstract setup(): Promise<void>;

  abstract showPopoverNotifications(force?: boolean): void;

  abstract subscribeToNotificationsEnabledChange(cb: (value: boolean) => void): void;

  abstract unsubscribeToNotificationsEnabledChange(): void;

  abstract subscribeToBrowserNotificationsDeniedChange(
    cb: (value: boolean) => void,
  ): void;

  abstract unsubscribeToBrowserNotificationsDeniedChange(): void;

  abstract subscribePopoverShown(cb: () => void): void;

  abstract unsubscribePopoverShown(): void;

  abstract subscribePopoverActions(cb: (event: PopoverActions) => void): void;

  abstract unsubscribePopoverActions(): void;

  abstract subscribePopoverClosed(cb: () => void): void;

  abstract unsubscribePopoverClosed(): void;

  abstract showNativePrompt(): void;
}
