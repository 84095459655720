import { SentryClient } from '@smh/monitoring/sentry-client';

import type {
  ILogger,
  LoggerCaptureInput,
} from '@jtnews/shared/seedwork/frontend/application';
import { LoggerError } from '@jtnews/shared/seedwork/frontend/application';

export type LoggerParams = {
  dsn: string;
  useProxy?: boolean;
  environment?: string;
  release?: string;
  extra?: LoggerCaptureInput['extra'];
  tags?: LoggerCaptureInput['tags'];
  include?: string[];
  exclude?: string[];
};

export class Logger implements ILogger {
  private readonly _sentry: SentryClient;

  constructor(params: LoggerParams) {
    const {
      dsn,
      useProxy = false,
      exclude = [],
      include = [],
      extra = [],
      tags,
      environment,
      release,
    } = params;

    try {
      this._sentry = new SentryClient({
        dsn,
        environment,
        release,
        useProxy,
        captureUnhandledErrors: false,
        extra,
        tags,
        exclude,
        include,
      });
    } catch (err) {
      throw LoggerError.of('Не удалось инициализировать sentry-клиент', err as Error);
    }
  }

  capture(input: LoggerCaptureInput) {
    const { error, extra = [], tags = {} } = input;

    if (this._sentry.checkAccessToSendError(error)) {
      try {
        this._sentry.capture({
          entity: error,
          extra,
          tags,
        });
      } catch (err) {
        throw LoggerError.of('Невозможно отправить ошибку', err as Error);
      }
    }
  }
}
