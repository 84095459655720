/* eslint-disable no-negated-condition */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable init-declarations */
/* eslint-disable func-style */
import { DirectiveBinding, DirectiveOptions } from 'vue/types/options';
import { VNode } from 'vue/types/vnode';

function deepEqual(val1: unknown, val2: unknown) {
  if (val1 === val2) {
    return true;
  }

  if (!val1 || !val2) {
    return false;
  }

  if (typeof val1 === 'object') {
    for (const key in val1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (!deepEqual(val1[key], val2[key])) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function processOptions(value: unknown): Options {
  let options: Options;
  if (typeof value === 'function') {
    // Simple options (callback-only)
    options = {
      intersection: {},
      callback: value as CallbackType,
    };
  } else {
    // Options object
    options = value as Options;
  }
  return options;
}

type IntersectionType = {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number;
  throttle?: number;
};

type CallbackType = (result: boolean, entry: IntersectionObserverEntry) => void;

type Options = {
  intersection: IntersectionType;
  callback: CallbackType;
  once?: boolean;
};

type ObserverElement = HTMLElement & {
  lazyLoadObserver?: IntersectionObserver;
  ['_vue_visibilityState']?: VisibilityState;
};

class VisibilityState {
  el: ObserverElement;
  observer: IntersectionObserver | null;
  frozen = false;
  options!: Options;
  callback!: CallbackType;
  oldResult: boolean | undefined = undefined;

  get threshold() {
    return this.options.intersection &&
      typeof this.options.intersection.threshold === 'number'
      ? this.options.intersection.threshold
      : 0;
  }

  constructor(el: ObserverElement, options: Options, vnode: VNode) {
    this.el = el;
    this.observer = null;
    this.frozen = false;

    this.createObserver(options, vnode);
  }

  createObserver(options: Options, vnode: VNode) {
    if (this.observer) {
      this.destroyObserver();
    }

    if (this.frozen) {
      return;
    }

    this.options = processOptions(options);

    this.callback = (result, entry) => {
      this.options.callback(result, entry);
      if (result && this.options.once) {
        this.frozen = true;
        this.destroyObserver();
      }
    };

    this.oldResult = undefined;

    this.observer = new IntersectionObserver((entries) => {
      let [entry] = entries;

      if (entries.length > 1) {
        const intersectingEntry = entries.find((e) => e.isIntersecting);
        if (intersectingEntry) {
          entry = intersectingEntry;
        }
      }

      if (this.callback) {
        // Use isIntersecting if possible because browsers can report isIntersecting as true, but intersectionRatio as 0, when something very slowly enters the viewport.
        const result = entry.isIntersecting && entry.intersectionRatio >= this.threshold;
        if (result === this.oldResult) {
          return;
        }
        this.oldResult = result;
        this.callback(result, entry);
      }
    }, this.options.intersection);

    // Wait for the element to be in document
    vnode.context?.$nextTick(() => {
      if (this.observer) {
        this.observer.observe(this.el);
      }
    });
  }

  destroyObserver() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }
}

function bind(el: ObserverElement, { value }: DirectiveBinding, vnode: VNode) {
  if (!value) {
    return;
  }
  if (typeof IntersectionObserver === 'undefined') {
    console.warn(
      '[vue-observe-visibility] IntersectionObserver API is not available in your browser. Please install this polyfill: https://github.com/w3c/IntersectionObserver/tree/master/polyfill',
    );
  } else {
    const state = new VisibilityState(el, value, vnode);
    el._vue_visibilityState = state;
  }
}

function updated(el: ObserverElement, binding: DirectiveBinding, vnode: VNode) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value, oldValue } = binding;
  if (deepEqual(value, oldValue)) {
    return;
  }

  const state = el._vue_visibilityState;
  if (!value) {
    unbind(el);
    return;
  }
  if (state) {
    state.createObserver(value, vnode);
  } else {
    bind(el, binding, vnode);
  }
}

function unbind(el: ObserverElement) {
  const state = el._vue_visibilityState;
  if (state) {
    state.destroyObserver();
    delete el._vue_visibilityState;
  }
}

const directive = {
  bind,
  updated,
  unbind,
};

const observeVisibility = typeof window !== 'undefined' ? directive : {};

export const observeVisibilityPlugin: { directive: DirectiveOptions } = {
  directive: observeVisibility,
};
