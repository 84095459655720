export enum Rubrics {
  Gorod = 'gorod',
  Winter = 'winter',
  Spring = 'spring',
  Summer = 'summer',
  Autumn = 'autumn',
  Economics = 'economics',
  Business = 'business',
  Relations = 'relations',
  Education = 'education',
  Job = 'job',
  Culture = 'culture',
  Entertainment = 'entertainment',
  Auto = 'auto',
  Transport = 'transport',
  Realty = 'realty',
  Politics = 'politics',
  Incidents = 'incidents',
  Criminal = 'criminal',
  House = 'house',
  World = 'world',
  Sport = 'sport',
  Food = 'food',
  Style = 'style',
  Health = 'health',
  Science = 'science',
  Animals = 'animals',
  Roads = 'roads',
  Family = 'family',
  Religion = 'religion',
  Ecology = 'ecology',

  // ниже старые рубрики, которые просто должны открываться
  // но, например, их не показываем в фильтрах и выводе на сайте
  Nature = 'nature',
  Tech = 'tech',
  Society = 'society',
  Relax = 'relax',
  Incident = 'incident',
  Relationship = 'relationship',
  Rabota = 'rabota',
  Pogoda = 'pogoda',
  Longreads = 'longreads',
  Longread = 'longread',
  Adv = 'adv',
}

// только актуальные рубрики, которые показываем на сайте
export type ActualRubricsIds =
  | Rubrics.Gorod
  | Rubrics.Winter
  | Rubrics.Spring
  | Rubrics.Summer
  | Rubrics.Autumn
  | Rubrics.Economics
  | Rubrics.Business
  | Rubrics.Relations
  | Rubrics.Education
  | Rubrics.Job
  | Rubrics.Culture
  | Rubrics.Entertainment
  | Rubrics.Auto
  | Rubrics.Transport
  | Rubrics.Realty
  | Rubrics.Politics
  | Rubrics.Incidents
  | Rubrics.Criminal
  | Rubrics.House
  | Rubrics.World
  | Rubrics.Sport
  | Rubrics.Food
  | Rubrics.Style
  | Rubrics.Health
  | Rubrics.Science
  | Rubrics.Animals
  | Rubrics.Family
  | Rubrics.Religion
  | Rubrics.Ecology;

export type RubricInfo = {
  sectionId: number[];
  value: string;
};

// только актуальные рубрики, которые показываем на сайте
/* eslint-disable @typescript-eslint/no-magic-numbers */
export const ACTUAL_RUBRICS_INFO: Record<ActualRubricsIds, RubricInfo> = {
  gorod: {
    sectionId: [105],
    value: 'Город',
  },
  winter: {
    sectionId: [109],
    value: 'Зима',
  },
  spring: {
    sectionId: [124],
    value: 'Весна',
  },
  summer: {
    sectionId: [115],
    value: 'Лето',
  },
  autumn: {
    sectionId: [117],
    value: 'Осень',
  },
  economics: {
    sectionId: [11],
    value: 'Экономика',
  },
  business: {
    sectionId: [158],
    value: 'Бизнес',
  },
  relations: {
    sectionId: [259],
    value: 'Он и она',
  },
  education: {
    sectionId: [166],
    value: 'Образование',
  },
  job: {
    sectionId: [295],
    value: 'Работа',
  },
  culture: {
    sectionId: [1],
    value: 'Культура',
  },
  entertainment: {
    sectionId: [7],
    value: 'Развлечения',
  },
  auto: {
    sectionId: [73],
    value: 'Авто',
  },
  transport: {
    sectionId: [294, 157],
    value: 'Дороги и транспорт',
  },
  realty: {
    sectionId: [144],
    value: 'Недвижимость',
  },
  politics: {
    sectionId: [15],
    value: 'Политика',
  },
  incidents: {
    sectionId: [13],
    value: 'Происшествия',
  },
  criminal: {
    sectionId: [87],
    value: 'Криминал',
  },
  house: {
    sectionId: [143],
    value: 'Мой дом',
  },
  world: {
    sectionId: [86],
    value: 'Страна и мир',
  },
  sport: {
    sectionId: [5],
    value: 'Спорт',
  },
  food: {
    sectionId: [96],
    value: 'Еда',
  },
  style: {
    sectionId: [141],
    value: 'Стиль и красота',
  },
  health: {
    sectionId: [9],
    value: 'Здоровье',
  },
  science: {
    sectionId: [296],
    value: 'Наука',
  },
  animals: {
    sectionId: [315],
    value: 'Животные',
  },
  religion: {
    sectionId: [2626],
    value: 'Религия',
  },
  ecology: {
    sectionId: [2629],
    value: 'Экология',
  },
  family: {
    sectionId: [2623],
    value: 'Семья',
  },
};
/* eslint-enable @typescript-eslint/no-magic-numbers */

export const allRubricsIds: string[] = Object.values(Rubrics);

export const RUBRICS_REGEX_IDS = allRubricsIds.reduce(
  (acc: string, current: string, index: number) => {
    const res = `${acc}${current}`;
    return index === allRubricsIds.length - 1 ? `${res})` : `${res}|`;
  },
  '(',
);

export const actualRubricsValues: RubricInfo[] = Object.values(ACTUAL_RUBRICS_INFO);

export const RUBRICS_REGEX_SECTION_IDS = actualRubricsValues.reduce(
  (acc: string, current: RubricInfo, index: number) => {
    const res = `${acc}${current.sectionId.join('|')}`;
    return index === actualRubricsValues.length - 1 ? `${res})` : `${res}|`;
  },
  '(',
);

export const getRubricIdBySectionId = (
  sectionId: number,
): ActualRubricsIds | undefined => {
  const e = Object.entries(ACTUAL_RUBRICS_INFO).find((entry) =>
    entry[1].sectionId.some((id) => id === sectionId),
  );

  if (e !== undefined) {
    return e[0] as ActualRubricsIds;
  }

  return undefined;
};
