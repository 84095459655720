import { JtClickHouse } from '@smh/analytics/clickhouse';
import { sendYM } from '@smh/analytics/core';
import { guardUnspecified } from '@smh/utils/guards';

import type {
  IReachGoalAnalytics,
  ReachGoal,
  ReachGoalProp,
  ReachGoalByProductName,
  ReachGoalByProps,
  ReachGoalOnlyGoal,
} from '@jtnews/shared/seedwork/frontend/domain';

type ReachGoalClickHouseEventParams = {
  url: string;
  referrer: string;
  eventName: string;
  experiments: string[];
  params: string | string[] | ReachGoalClickHouseEventParamsValue;
  plainQueryParams?: Record<string, string | string[]>;
  path?: string;
};

type ReachGoalClickHouseEventParamsValue = {
  [key: string]:
    | Record<string, ReachGoalClickHouseEventParamsValue | string | string[]>
    | string
    | string[];
};

export type ReachGoalAnalyticsConfig = {
  yandex: {
    counters: number[];
  };
  domain: string;
  experiments: string[];
  sendOnlyByProps?: boolean;
};

export class ReachGoalAnalytics implements IReachGoalAnalytics {
  private readonly _clickhouseService: JtClickHouse;

  constructor(private readonly _config: ReachGoalAnalyticsConfig) {
    const { domain } = _config;
    this._clickhouseService = new JtClickHouse(domain);
  }

  private get _sendOnlyByProps() {
    return this._config.sendOnlyByProps;
  }

  private get _experiments() {
    return this._config.experiments;
  }

  private get _yandexCounters() {
    return this._config.yandex.counters;
  }

  send(input: { event: ReachGoalByProductName; params?: unknown }): void;
  send(input: { event: ReachGoalByProps; params?: unknown }): void;
  send(input: { event: ReachGoalOnlyGoal; params?: unknown }): void;

  send(input: { event: ReachGoal; params?: unknown }): void {
    const { event, params } = input;

    if (typeof event === 'string') {
      this._sendYM({
        counters: this._yandexCounters,
        goalName: event,
        params,
      });
      this._sendClickHouse(
        this._getClickHouseParamsByOnlyGoal({ goalName: event, params }),
      );
      return;
    }

    const { goalName } = event;

    if (this._sendOnlyByProps === true && this._guardReachGoalByProps(event)) {
      this._sendYM({
        counters: this._yandexCounters,
        goalName,
        params: this._getParamsFromReachGoalByProps(event),
      });
      this._sendClickHouse(this._getClickHouseParamsByProps(event));

      return;
    }

    if (this._guardReachGoalByProductName(event)) {
      this._sendYM({
        counters: this._yandexCounters,
        goalName,
        params: this._getParamsFromReachGoalByProductName(event),
      });
      this._sendClickHouse(this._getClickHouseParamsByProductName(event));
    }
  }

  private _sendYM(input: { counters: number[]; goalName: string; params: unknown }) {
    const { counters, goalName, params } = input;

    sendYM('reachGoal', {
      counters,
      goalName,
      params,
    });
  }

  private _sendClickHouse(event: ReachGoalClickHouseEventParams) {
    this._clickhouseService.sendEvent(event);
  }

  private _getClickHouseParams(goalName: string, params: string[]) {
    return {
      url: document.location.href,
      referrer: document.referrer,
      eventName: goalName,
      experiments: this._experiments,
      params,
    };
  }

  private _getClickHouseParamsByOnlyGoal(rg: {
    goalName: ReachGoalOnlyGoal;
    params?: unknown;
  }): ReachGoalClickHouseEventParams {
    const params: string[] = guardUnspecified(rg.params)
      ? this._saveClickHouseParams(rg.params).map((item) => encodeURIComponent(item))
      : [];
    return this._getClickHouseParams(rg.goalName, params);
  }

  private _getClickHouseParamsByProductName(
    rg: ReachGoalByProductName,
  ): ReachGoalClickHouseEventParams {
    let params: string[] = [
      rg.pageName,
      rg.blockType ?? '',
      rg.fieldType,
      guardUnspecified(rg.productName) ? rg.productName : 'Продукт',
    ];

    if (guardUnspecified(rg.valueName)) {
      params = [...params, ...this._saveClickHouseParams(rg.valueName)].map((item) =>
        encodeURIComponent(item),
      );
    }

    return this._getClickHouseParams(rg.goalName, params);
  }

  private _getClickHouseParamsByProps(
    rg: ReachGoalByProps,
  ): ReachGoalClickHouseEventParams {
    let params: string[] = [rg.pageName, rg.blockType, rg.actionType];

    if (guardUnspecified(rg.prop1)) {
      params.push(rg.prop1);
    }

    if (guardUnspecified(rg.prop2)) {
      params = [...params, ...this._saveClickHouseParams(rg.prop2)].map((item) =>
        encodeURIComponent(item),
      );
    }

    return this._getClickHouseParams(rg.goalName, params);
  }

  private _saveClickHouseParams(valueName: ReachGoalProp): string[] {
    const paramValues: string[] = [];
    const saveNestedParams = (valueName: ReachGoalProp) => {
      if (typeof valueName === 'object' && !Array.isArray(valueName)) {
        const [nestedKey] = Object.keys(valueName);
        const nestedParam = valueName[nestedKey];

        paramValues.push(nestedKey);
        saveNestedParams(nestedParam);
      } else if (Array.isArray(valueName)) {
        paramValues.push(...valueName);
      } else {
        paramValues.push(valueName.toString());
      }
    };
    saveNestedParams(valueName);
    return paramValues;
  }

  private _getParamsFromReachGoalByProps(rg: ReachGoalByProps) {
    const { pageName, blockType, actionType, prop1, prop2 } = rg;

    return {
      [pageName]: {
        [blockType]: guardUnspecified(prop1)
          ? {
              [actionType]: guardUnspecified(prop2)
                ? {
                    [prop1]: prop2,
                  }
                : prop1.toString(),
            }
          : actionType,
      },
    };
  }

  private _getParamsFromReachGoalByProductName(rg: ReachGoalByProductName) {
    const { pageName, blockType, productName = 'Продукт', valueName, fieldType } = rg;

    const pageNameContent = guardUnspecified(blockType)
      ? {
          [blockType]: {
            [fieldType]: valueName,
          },
        }
      : {
          [fieldType]: valueName,
        };

    return {
      [productName]: {
        [pageName]: pageNameContent,
      },
    };
  }

  private _guardReachGoalByProps(rg: ReachGoal): rg is ReachGoalByProps {
    return (
      typeof rg !== 'string' && 'actionType' in rg && typeof rg.actionType === 'string'
    );
  }

  private _guardReachGoalByProductName(rg: ReachGoal): rg is ReachGoalByProductName {
    return (
      typeof rg !== 'string' && 'fieldType' in rg && typeof rg.fieldType === 'string'
    );
  }
}
