export type OriginalError = {
  message: string;
  stack: string;
  response?: {
    status: number;
    statusText: string;
    data: unknown;
  };
  config?: {
    url?: string;
    params?: unknown;
    data?: unknown;
  };
};

export class NewsApiClientError extends Error {
  public readonly response?: {
    status: number;
    statusText: string;
    data: unknown;
  };

  public readonly request: {
    url?: string;
    args: unknown;
    params?: unknown;
    data?: unknown;
  };

  public readonly originalStack: string;

  constructor(originalError: OriginalError, args: unknown[]) {
    super(originalError.message);
    this.name = this.constructor.name;

    if ('captureStackTrace' in Error) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.originalStack = originalError.stack;

    if (originalError.response) {
      this.response = {
        status: originalError.response.status,
        statusText: originalError.response.statusText,
        data: originalError.response.data,
      };
    }

    this.request = {
      args,
    };

    if (originalError.config) {
      this.request = {
        ...this.request,
        url: originalError.config.url,
        params: originalError.config.params,
        data: originalError.config.data,
      };
    }
  }
}
