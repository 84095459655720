/* eslint-disable */
import http from 'http';
import https from 'https';

import axios, { AxiosInstance, AxiosStatic } from 'axios';

import { getDomainByRegionId, Regions } from '@jtnews/shared/seedwork/bff/domain';

export type BaseConfig = {
  acceptEncoding?: string;
  envType?: string;
  protocol?: 'http' | 'https';
  enableKeepAlive?: boolean;
};

export class BaseHttpClient {
  public static version = 'v1';
  public static accept = 'vnd.news.v1.jtnews+json';
  // 10 sec timeout
  private readonly _timeout: number = 10000;
  // follow up to 10 HTTP 3xx redirects
  private readonly _maxRedirects: number = 10;
  // cap the maximum content length we'll accept to 50MBs, just in case
  private readonly _maxContentLength: number = 50000000;
  private readonly _config: BaseConfig;
  private readonly _httpClient: AxiosStatic | AxiosInstance;

  protected getHost(regionId: Regions): string {
    return `${this.protocol}://newsapi.${getDomainByRegionId(regionId)}${this.envType}/`;
  }

  public get v(): string {
    return BaseHttpClient.version;
  }

  public get accept(): string {
    return BaseHttpClient.accept;
  }

  public get httpClient(): AxiosStatic | AxiosInstance {
    return this._httpClient;
  }

  public get envType(): string {
    if (this._config.envType !== undefined) {
      return this._config.envType;
    }

    return '';
  }

  public get protocol(): 'http' | 'https' {
    if (this._config.protocol !== undefined) {
      return this._config.protocol;
    }

    return 'http';
  }

  constructor(config: BaseConfig) {
    this._config = config;
    this._httpClient = this._setupHttpClient();
  }

  private _setupHttpClient(): AxiosStatic | AxiosInstance {
    if (this._config.acceptEncoding !== undefined) {
      axios.defaults.headers.get['Accept-Encoding'] = this._config.acceptEncoding;
    }

    if (this._config.enableKeepAlive) {
      const client = axios.create({
        timeout: this._timeout,

        httpAgent: new http.Agent({ keepAlive: true }),
        httpsAgent: new https.Agent({ keepAlive: true }),

        maxRedirects: this._maxRedirects,

        maxContentLength: this._maxContentLength
      });

      this._initInterceptors(client);

      return client;
    }

    this._initInterceptors(axios);

    return axios;
  }

  private _initInterceptors(i: AxiosStatic | AxiosInstance): void {
    i.interceptors.request.use(
      (config: any) => {
        config.metadata = { startTime: Date.now() };
        return config;
      },
      (error: any) => Promise.reject(error)
    );

    i.interceptors.response.use(
      (response: any) => {
        response.config.metadata.endTime = Date.now();

        if (typeof response.data !== undefined && typeof response.data === 'string') {
          response.data = {};
        }

        response.data.responseTime =
          response.config.metadata.endTime - response.config.metadata.startTime;
        return response;
      },
      (error: any) => Promise.reject(error)
    );
  }
}
