<template>
  <LazyHydrate
    :never="ssrOnly"
    :on-interaction="onInteraction"
    :idle-timeout="idleTimeout"
    :trigger-hydration="triggerHydration"
    :when-idle="whenIdle"
    :when-visible="whenVisible"
  >
    <slot />
  </LazyHydrate>
</template>

<script>
  /* eslint-disable @typescript-eslint/no-unsafe-assignment, vue/require-default-prop */

  import LazyHydrate from 'vue-lazy-hydration';

  export default {
    components: {
      LazyHydrate,
    },
    props: {
      idleTimeout: {
        default: 2000,
        type: Number,
      },
      onInteraction: {
        type: [Array, Boolean, String],
        required: false,
      },
      ssrOnly: {
        type: Boolean,
        required: false,
      },
      triggerHydration: {
        type: Boolean,
        default: false,
      },
      whenIdle: {
        type: Boolean,
        required: false,
      },
      whenVisible: {
        type: [Boolean, Object],
        required: false,
      },
    },
  };
</script>
