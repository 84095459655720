import { Regions } from '@smh/projects/regions';
import { AxiosRequestConfig } from 'axios';

import { BaseConfig, BaseHttpClient, catchError } from '../base';

type NotificationType = 'COMMENTS_NEW_ANSWER';

type UserParamsForPushApi = {
  profileId: string;
  regionId: Regions;
  playerId?: string;
  notificationTypes?: string[];
};

type PushNotificationsApiResponse = {
  statusCode: number;
  error: string;
  type: string;
  settings: [];
  params: {
    notificationType: NotificationType;
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  reach_goal: null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ga_events: null;
  data: {
    internalId: string;
  };
};

export class PushNotificationsHttpClient extends BaseHttpClient {
  private readonly _basePushNotificationsUrl = '/public/users/';

  constructor(config: BaseConfig) {
    super(config);
  }

  @catchError
  async saveOneSignalSubscription({
    regionId,
    playerId,
    notificationTypes,
    profileId,
  }: UserParamsForPushApi): Promise<string> {
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._basePushNotificationsUrl}${profileId}/subscription`;
    const options: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json',
      },
    };

    const data = {
      externalServiceId: playerId,
      notificationTypes,
      regionId,
    };

    const result = await this.httpClient.patch<PushNotificationsApiResponse>(
      url,
      data,
      options,
    );

    return result.data.data.internalId;
  }

  @catchError
  async unsubscribeUser({
    regionId,
    playerId,
    notificationTypes,
    profileId,
  }: UserParamsForPushApi): Promise<void> {
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._basePushNotificationsUrl}${profileId}/subscription`;
    const options: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json',
      },
      params: {
        externalServiceId: playerId,
        regionId,
        notificationTypes,
      },
    };

    await this.httpClient.delete<PushNotificationsApiResponse>(url, options);
  }
}
