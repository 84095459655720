export enum PopoverActions {
  Allow = 'allow',
  Disallow = 'disallow',
}

export interface IPushNotifications {
  setup: () => Promise<void>;
  showPopoverNotifications: (force?: boolean) => void;
  subscribeToNotificationsEnabledChange: (cb: (value: boolean) => void) => void;
  unsubscribeToNotificationsEnabledChange: () => void;
  subscribeToBrowserNotificationsDeniedChange: (cb: (value: boolean) => void) => void;
  unsubscribeToBrowserNotificationsDeniedChange: () => void;
  subscribePopoverShown: (cb: () => void) => void;
  unsubscribePopoverShown: () => void;
  subscribePopoverActions: (cb: (event: PopoverActions) => void) => void;
  unsubscribePopoverActions: () => void;
  subscribePopoverClosed: (cb: () => void) => void;
  unsubscribePopoverClosed: () => void;
  showNativePrompt(): void;
}
