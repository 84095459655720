/* eslint-disable */
import { NewsApiClientError, OriginalError } from '../error';

export function catchError(
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor
) {
  const originalMethod = descriptor.value;

  descriptor.value = async function (...args: any[]) {
    try {
      return await originalMethod.apply(this, args);
    } catch (error) {
      throw new NewsApiClientError(error as OriginalError, args);
    }
  };

  return descriptor;
}
