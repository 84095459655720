import { AppError } from '../app-error';

export class LoggerError extends AppError {
  name: 'LoggerError';

  private constructor(message: string, error?: Error) {
    super(message, error);

    this.name = 'LoggerError';
  }

  static of(message: string, error?: Error) {
    return new LoggerError(message, error);
  }
}
