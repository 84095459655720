export enum Regions {
  Arkhangelsk = 29,
  Barnaul = 22,
  Chelyabinsk = 74,
  Fontanka = 78,
  Irkutsk = 38,
  Izhevsk = 18,
  Kazan = 16,
  Kemerovo = 42,
  KemerovoRugion = 142,
  KhantyMansiysk = 86,
  Kirov = 43,
  KrasnodarRugion = 23,
  Krasnoyarsk = 24,
  KrasnoyarskRugion = 124,
  Kurgan = 45,
  Lipetsk = 48,
  Magnitogorsk = 182028,
  Mosk = 1077,
  Murmansk = 51,
  NizhnyNovgorod = 52,
  NovosibirskRugion = 154,
  Nsk = 54,
  Omsk = 55,
  OmskRugion = 155,
  Orenburg = 56,
  Perm = 59,
  Pskov = 60,
  Rostov = 61,
  Ryazan = 62,
  SaintPetersburg = 47,
  Samara = 63,
  Saratov = 64,
  Sochi = 93,
  Stavropol = 26,
  Sterlitamak = 114160,
  Tambov = 68,
  Tolyatty = 142982,
  Tomsk = 70,
  TomskRugion = 170,
  Tula = 71,
  Tyumen = 72,
  Ufa = 2,
  Ulyanovsk = 73,
  VelikiiNovgorod = 53,
  Volgograd = 34,
  Vologda = 35,
  Voronezh = 36,
  Yakutsk = 14,
  YamaloNenetsAutonomousArea = 89,
  Yaroslavl = 76,
  Yekaterinburg = 66,
  YekaterinburgRugion = 166,
  Chita = 75,
  Vladivostok = 25,
  DoubleNsk = 1154,
}

export const MACRO_REGIONS = [
  Regions.Nsk,
  Regions.Omsk,
  Regions.Krasnoyarsk,
  Regions.Kemerovo,
  Regions.Barnaul,
  Regions.Irkutsk,
  Regions.Tomsk,
  Regions.Kazan,
  Regions.KrasnodarRugion,
  Regions.KhantyMansiysk,
  Regions.Tolyatty,
  Regions.Mosk,
  Regions.Chita,
  Regions.Vladivostok,
  Regions.Orenburg,
  Regions.Tula,
  Regions.Yakutsk,
  Regions.Murmansk,
  Regions.Ulyanovsk,
  Regions.Izhevsk,
];

export const RUGION_REGIONS = [
  Regions.Ufa,
  Regions.Arkhangelsk,
  Regions.Volgograd,
  Regions.Kurgan,
  Regions.Perm,
  Regions.Rostov,
  Regions.Samara,
  Regions.Tyumen,
  Regions.Chelyabinsk,
  Regions.Yaroslavl,
  Regions.Magnitogorsk,
];

export const BRANCHLESS_REGIONS = [
  Regions.KrasnoyarskRugion,
  Regions.Stavropol,
  Regions.Vologda,
  Regions.Voronezh,
  Regions.KemerovoRugion,
  Regions.Kirov,
  Regions.Lipetsk,
  Regions.VelikiiNovgorod,
  Regions.NovosibirskRugion,
  Regions.OmskRugion,
  Regions.Pskov,
  Regions.Ryazan,
  Regions.Saratov,
  Regions.YekaterinburgRugion,
  Regions.Tambov,
  Regions.SaintPetersburg,
  Regions.YamaloNenetsAutonomousArea,
  Regions.TomskRugion,
  Regions.Sochi,
  Regions.Sterlitamak,
];

export const ALL_REGIONS = [
  ...MACRO_REGIONS,
  ...RUGION_REGIONS,
  ...BRANCHLESS_REGIONS,
  Regions.Yekaterinburg,
  Regions.NizhnyNovgorod,
  Regions.DoubleNsk,
];
